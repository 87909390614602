import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import "./hero.scss"

function Hero({ children, heroImage, imgAlt }){

    return(
        <div className="hero">
            <div className="container">
                <div className="hero__text">
                    { children }
                </div>
                <GatsbyImage className="hero__image" image={heroImage} quality="100" layout="fixed" alt={imgAlt}></GatsbyImage>
            </div>
        </div>
    )
}

export default Hero