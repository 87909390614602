import PropTypes from "prop-types"
import React, { useState } from "react"
// import Loadable from "@loadable/component"
// Style Imports
import "./Accordion.scss"
// Content Imports

function Accordion({ hidden, title, subTitle, addedClass, children }) {
    const [expanded, setExpanded] = useState(!hidden)

    return (
        <div className={"accordion " + (expanded ? "accordion--open" : "") + (addedClass ? addedClass : "")} role="tablist">
            <div className="accordion__label" role="tab">
                <h3>
                    <button
                        className="accordion__btn"
                        aria-expanded={expanded}
                        onClick={() => {
                            setExpanded(!expanded)
                        }}
                    >
                        <span className="sr-only">{!expanded ? "expand" : "collapse"}</span>
                        <span className="accordion__icon">
                            {/* <ArrowIcon /> */}
                            <p className="read-more">Read More</p>
                            <p className="read-less">Read Less</p>
                        </span>
                        <span className="accordion__heading">{title}</span> <br />
                        <span className="accordion__subheading">{subTitle}</span>
                    </button>
                </h3>
            </div>
            <div className="accordion__content" hidden={!expanded} aria-hidden={!expanded}>
                {children}
            </div>
        </div>
    )
}

export default Accordion

Accordion.propTypes = {
    title: PropTypes.string,
    hidden: PropTypes.bool,
    addedClass: PropTypes.string,
}

Accordion.defaultProps = {
    title: null,
    hidden: true,
    addedClass: '',
}
